import { MapItem } from '@capturum/ui/api';

export class ScanStartShipment {
  public static readonly type = '[Start Shipment] Scanned shipment order';

  constructor(public shipment: MapItem) {}
}

export class ResetStartShipment {
  public static readonly type = '[Start Shipment] Reset Start Shipment';
}
